import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import UserService from "@/core/services/user.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    roles: {},
    company: null,
  },
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("authentication_token", credentials)
        .then(({ data }) => {
          let payload = {
            token: null,
            user: null,
          };
          payload.token = data.token;

          ApiService.get("users/", "?email=" + credentials.email).then(
            ({ data }) => {
              payload.user = data["hydra:member"][0];
              context.commit(SET_AUTH, payload);
              resolve(data);
            }
          );
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.get("users/" + UserService.getUser()).then(({ data }) => {
        context.commit(SET_AUTH, {
          token: JwtService.getToken(),
          user: data,
        });
      });

      // // ApiService.setHeader();
      // ApiService.post("authentication_token")
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data.errors);
      //   });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user.id = data.user.id;
    state.user.firstName = data.user.firstName;
    state.user.lastName = data.user.lastName;
    state.user.email = data.user.email;
    state.user.roles = data.user.roles;
    state.errors = {};
    JwtService.saveToken(data.token);
    UserService.saveUser(data.user.id);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    UserService.destroyUser();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
