// HR
export const locale = {
  TRANSLATOR: {
    SELECT: "Odaberite svoj jezik",
  },
  MENU: {
    NEW: "novo",
    ACTIONS: "Akcije",
    CREATE_POST: "Izradite objavu",
    PAGES: "Stranice",
    FEATURES: "Značajke",
    APPS: "Aplikacije",
    DASHBOARD: "Nadzorna ploča",
    WORKERS: "Radnici",
    ALL_WORKERS: "Svi radnici",
    CONSTRUCTIONS: "Gradilišta",
    WORKERS_AND_CONSTRUCTIONS: "Radnici i gradilišta",
    ALL_CONSTRUCTIONS: "Sva gradilišta",
    HOURLY_RATE_ADDED: "Satnica je uspješno dodana u bazu podataka",
    FINANCE: "Financije",
    HOURLY_RATE: "Satnica",
    ALL_HOURLY_RATES: "Sve satnice",
    NEW_HOURLY_RATE: "Nova satnica",
    COST_SHEET: "Troškovnik",
    ALL_COST_SHEETS: "Svi troškovnici",
    TOTAL: "Ukupno",
    USERS: "Korisnici",
    ALL_USERS: "Svi korisnici",
  },
  USER: {
    DELETE_CONFIRMATION: "Želite li izbrisati ovog korisnika",
    DELETED_SUCCESS: "Korisnik uspješno izbrisan iz baze podataka.",
    EXPORT_SUCCESS: "Uspješan izvoz korisnika",
    EXPORT_ERROR: "Neuspješan izvoz korisnika",
    ALL: "Svi korisnici",
    EDIT_USER: "Uredi korisnika",
    NEW_USER: "Dodaj novog korisnika",
    ADD_BUTTON: "Dodaj korisnika",
    NEW_PASSWORD: "Nova zaporka",
    REPEAT_NEW: "Ponovi novu zaporku",
    REPEAT_PASSWORD: "Ponovi zaporku",
    NO_MATCH: "Ponovljena zaporka ne odgovara",
    ROLE: "Uloga",
    SELECT_ROLE: "Odaberite ulogu",
    ADD_SUCCESS: "Korisnik uspješno dodan u bazu podataka.",
    SUCCESS: "Korisnik uspješno ažuriran u bazi podataka.",
    MANDATORY: "Unesite sva obavezna polja",
    THIS_MANDATORY: "Ovo polje je obavezno",
    LIST: "Popis svih korisnika u bazi podataka.",
  },
  GENERAL: {
    FIELD_REQUIRED: "Ovo polje je obavezno",
    ACTION: "Akcija",
    AMOUNT: "Iznos",
    NEW: "Nova satnica",
    ADD_HOURLYRATE: "Dodaj satnicu",
    SAVE_HOURLYRATE: "Spremi satnicu",
    WEEKEND: "Vikend",
    TOTAL: "Ukupno",
    TOTAL_WORKERS: "Ukupno radnika",
    TOTAL_CONSTRUCTIONSITES: "Ukupno gradilišta",
    WORK_HOURS: "Radni sati",
    CONSTRUCTION_SITE: "Gradilište",
    DATE: "Datum",
    YES: "Da",
    NO: "Ne",
    EXPIRED: "Istekla",
    HAS: "Ima",
    DOESNT_HAVE: "Nema",
    ADD_NEW: "Dodaj novo",
    NAME: "Ime",
    LAST_NAME: "Prezime",
    FIRST_LAST: "Ime i prezime",
    ADDRESS: "Adresa",
    PASSPORT: "Putovnica",
    OIB: "OIB",
    MOBILE: "Broj mobitela",
    EMAIL: "Email adresa",
    FATHERS_NAME: "Ime oca",
    MOTHERS_NAME: "Ime majke",
    SALARY_AND_HOURLYRATE: "Plaća i satnica",
    SALARY: "Plaća",
    OVERTIME: "Prekovremeno",
    HOURLY_RATE: "Satnica",
    OVERTIME_RATE: "Prekovremena satnica",
    WEEKEND_HOURLYRATE: "Vikend satnica",
    WEEKEND_HOURS: "Radni sati vikendom",
    DAILY_WAGE: "Dnevnica",
    NIGHT_HOURLYRATE: "Noćna satnica",
    NIGHT_HOURS: "Noćni sati",
    WORK_PERMIT_EXPIRATION_DATE: "Radna dozvola vrijedi do",
    MEDICAL_EXPIRATION_DATE: "Lječnički pregled vrijedi do",
    WORK_SAFETY: "Zaštita na radu",
    FIRST_AID: "Prva pomoć",
    GEDA: "GEDA",
    BORROWED_TOOLS: "Posuđeni alat",
    START: "Početak",
    END: "Kraj",
    PROOF: "Dokaznica",
    BILL: "Račun",
    CHARGED: "Naplaćeno",
    TOOLS: "Alat",
    NOTE: "Napomena",
  },
  CONSTRUCTIONS: {
    EDIT_CONSTRUCTION: "Uredi gradilište",
    EDIT_CONSTRUCTION_NAME: "Uredi ime gradilišta",
    EDIT_ADDRESS: "Uredi adresu",
    EDIT_PROOF: "Uredi dokaznicu",
    EDIT_BILL: "Uredi račun",
    EDIT_CHARGED: "Uredi naplaćeno",
    EDIT_START: "Promijeni početak",
    EDIT_END: "Promijeni kraj",
    EDIT_NOTE: "Uredi napomenu",
    ADDED: "Gradilište uspješno dodanu u bazu podataka",
    DELETED: "Gradilište uspješno izbrisano iz baze podataka",
    DEL_CONFIRM: "Želite li izbrisati gradilište iz baze podataka?",
    EXPORT: "Gradilište uspješno izvedeno",
    EXPORT_ERR: "Neuspješan izvoz gradilišta",
  },
  ADD: {
    ADD_CONSTRUCTION: "Dodaj novo gradilište",
    ADD_PROOF: "Dodaj dokaznicu",
    ADD_BILL: "Dodaj račun",
    ADD_WORKERS: "Dodaj radnika",
    ADD_NEW_WORKER: "Dodaj novog radnika",
  },
  LIST: {
    ADDRESS: "Adresa",
    ALL_CONSTRUCTIONS: "Sva gradilišta",
    ADD_CONSTRUCTION: "Dodaj gradilište",
    LIST_CONSTRUCTION: "Lista gradilišta u bazi",
    LIST_OF_ALL_WORKERS_IN_DATABASE: "Lista svih radnika u bazi podataka.",
    LIST_OF_ALL_HOURLYRATES_IN_DATABASE: "Lista svih satnica u bazi podataka",
    NO_HOURLYRATES: "Trenutno nema dodanih satnica u bazi",
    NO_CONSTRUCTIONS: "Trenutno nema dodanih gradilišta u bazi podataka.",
    NO_WORKERS_IN_DATABASE: "Trenutno nema dodanih radnika u bazi podataka.",
    NEW_CONSTRUCTION: "Novo gradilište",
    NEW_WORKER: "Novi radnik",
    NAME: "Naziv",
    ACTIONS: "Akcije",
    FULL_NAME: "Ime i prezime",
  },
  WORKERS: {
    EDIT_WORKER: "Uredi radnika",
    PERSONAL_DETAILS: "Osobni podaci radnika",
    WORKER_SUCCESS: "Radnik uspješno dodan u bazu podataka",
    EXPORT_SUCCESS: "Uspješan izvoz radnika",
    EXPORT_ERR: "Neupješan izvoz radnika",
    DELETE_CONFIRMATION: "Želite li izbrisati ovog radnika?",
    DELETE_SUCCESS: "Radnik uspješno izbrisan iz baze podataka",
    UPDATE_SUCCESS: "Radnik uspješno izmjenjen u bazi podataka",
    UPDATE_ERR: "Radnik neuspješno izmjenjen",
  },
  COSTSHEET: {
    CONFIRMATION: "Jeste li sigurni da želite izbrisati troškovnik",
    ALL_TOTAL: "Sve tablice s ukupnim troškovima",
    COST: "Trošak",
    COSTSHEET: "Troškovnik",
    IS_VARIABLE: "Je varijabilan",
    ADD_COSTSHEET: "Dodaj troškovnik",
    ADDED: "Troškovnik uspješno dodan u bazu podataka",
    NO_COSTSHEET_IN_DATABASE:
      "Trenutno nema dodanih troškovnika u bazi podataka.",
    COSTSHEET_UPDATED: "Troškovnik uspješno izmjenjen u bazi podataka",
    DELETED: "Troškovnik uspješno izbrisan iz baze podataka",
    NEW_COSTSHEET: "Novi troškovnik",
    EDIT_COSTSHEET: "Uredi troškovnik",
    COST_DESCRIPTION: "Opis troška",
    BILLING_DATE: "Datum naplate",
    FROM_DATE: "Od datuma",
    TO_DATE: "Do datuma",
    LIST_OF_COSTSHEETS: "Popis svih troškovnika u bazi podataka",
    EXPORT: "Troškovnik uspješno izveden",
    EXPORT_ERR: "Neuspješan izvoz troškovnika",
  },
  HOURLYRATE: {
    EDIT_HOURLYRATE: "Uredi satnicu",
    SUCCESS: "Satnica uspješno ažurirana u bazi podataka",
    EXPORT: "Satnica uspješno izvedena",
    EXPORT_ERR: "Izvedba satnice neuspješna",
  },
  ERROR: {
    SOMETHING_WENT_WRONG: "Nešto je pošlo po zlu",
    OOPS: "UPS!",
    HOW_DID_YOU_GET_HERE: "Kako ste došli ovdje?",
    SORRY_CANT_FIND_PAGE:
      "Nažalost, čini se da ne možemo pronaći stranicu koju tražite.",
    URL_MISSPELLING: "Uneseni URL može biti pogrešno napisan",
    OR: "ili stranica koju tražite više ne postoji",
    ERR: "Greška",
    NOTHING_HERE: "Nema više ništa za učiniti ovdje",
    WORKING_ON_IT: "Radimo na rješenju",
  },
  AUTH: {
    GENERAL: {
      WELCOME: "Dobrodošli u Construction Tycoon",
      OR: "Ili",
      DETAILS: "Unesite podatke da biste se prijavili",
      SUBMIT_BUTTON: "Potvrdi",
      CANCEL: "Otkaži",
      NO_ACCOUNT: "Nemate račun?",
      SIGNUP_BUTTON: "Prijavite se",
      FORGOT_BUTTON: "Zaboravljena zaporka",
      BACK_BUTTON: "Nazad",
      PRIVACY: "Privatnost",
      LEGAL: "Pravno",
      CONTACT: "Kontakt",
    },
    LOGIN: {
      TITLE: "Prijava na račun",
      BUTTON: "Prijavi se",
    },
    FORGOT: {
      TITLE: "Zaboravljena lozinka?",
      DESC: "Unesite svoj email za resetiranje lozinke",
      SUCCESS: "Vaš račun uspješno resetiran.",
    },
    REGISTER: {
      TITLE: "Registracija",
      DESC: "Unesite svoje podatke za kreiranje računa",
      SUCCESS: "Vaš račun uspješno registriran.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Ime i prezime",
      PASSWORD: "Lozinka",
      CONFIRM_PASSWORD: "Potvrdite lozinku",
      USERNAME: "Korisničko ime",
    },
    VALIDATION: {
      INVALID: "{{name}} nije valjan",
      REQUIRED: "{{name}} je obavezno",
      MIN_LENGTH: "Minimalna duljina {{name}} je {{min}}",
      AGREEMENT_REQUIRED: "Prihvaćanje uvjeta i odredbi je obavezno",
      TERMS_AND_CONDITIONS: "Odredbe i uvijeti",
      AGREE: "Slažem se",
      NOT_FOUND: "Traženi {{name}} nije pronađen",
      INVALID_LOGIN: "Podaci za prijavu nisu ispravni",
      REQUIRED_FIELD: "Obavezno polje",
      MIN_LENGTH_FIELD: "Minimalna duljina polja:",
      MAX_LENGTH_FIELD: "Maksimalna duljina polja:",
      INVALID_FIELD: "Polje nije valjano",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Broj odabranih zapisa: ",
      ALL: "Svi",
      SUSPENDED: "Suspendirani",
      ACTIVE: "Aktivni",
      FILTER: "Filter",
      BY_STATUS: "po statusu",
      BY_TYPE: "po vrsti",
      BUSINESS: "Poslovni",
      INDIVIDUAL: "Pojedinačni",
      SEARCH: "Pretraži",
      IN_ALL_FIELDS: "u svim poljima",
    },
    ECOMMERCE: "eTrgovina",
    CUSTOMERS: {
      CUSTOMERS: "Kupci",
      CUSTOMERS_LIST: "Popis kupaca",
      NEW_CUSTOMER: "Novi kupac",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Brisanje kupca",
        DESCRIPTION: "Jeste li sigurni da želite trajno izbrisati ovog kupca?",
        WAIT_DESCRIPTION: "Kupac se briše...",
        MESSAGE: "Kupac je uspješno izbrisan",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Brisanje kupaca",
        DESCRIPTION:
          "Jeste li sigurni da želite trajno izbrisati odabrane kupce?",
        WAIT_DESCRIPTION: "Kupci se brišu...",
        MESSAGE: "Odabrani kupci su uspješno izbrisani",
      },
      UPDATE_STATUS: {
        TITLE: "Status je ažuriran za odabrane kupce",
        MESSAGE: "Status odabranih kupaca uspješno je ažuriran",
      },
      EDIT: {
        UPDATE_MESSAGE: "Kupac je ažuriran",
        ADD_MESSAGE: "Kupac je uspješno stvoren",
      },
    },
  },
};
