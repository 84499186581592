import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/workers",
          name: "workers",
          component: () => import("@/view/pages/workers/Workers"),
          children: [
            {
              path: "list",
              name: "workers-list",
              component: () => import("@/view/pages/workers/List.vue"),
            },
            {
              path: "new",
              name: "worker-new",
              component: () => import("@/view/pages/workers/New.vue"),
            },
            {
              path: "view/:id",
              name: "worker-view",
              component: () => import("@/view/pages/workers/View.vue"),
            },
            {
              path: "edit/:id",
              name: "worker-edit",
              component: () => import("@/view/pages/workers/Edit.vue"),
            },
          ],
        },
        {
          path: "/constructions",
          name: "constructions",
          component: () => import("@/view/pages/constructions/Constructions"),
          children: [
            {
              path: "list",
              name: "constructions-list",
              component: () => import("@/view/pages/constructions/List.vue"),
            },
            {
              path: "new",
              name: "construction-new",
              component: () => import("@/view/pages/constructions/New.vue"),
            },
            {
              path: "edit/:id",
              name: "construction-edit",
              component: () => import("@/view/pages/constructions/Edit.vue"),
            },
          ],
        },
        {
          path: "/hourly-rates",
          name: "hourly-rates",
          component: () => import("@/view/pages/hourly-rates/HourlyRate.vue"),
          children: [
            {
              path: "list",
              name: "hourly-rates-list",
              component: () => import("@/view/pages/hourly-rates/List.vue"),
            },
            {
              path: "new",
              name: "hourly-rate-new",
              component: () => import("@/view/pages/hourly-rates/New.vue"),
            },
            {
              path: "edit/:id",
              name: "hourly-rate-edit",
              component: () => import("@/view/pages/hourly-rates/Edit.vue"),
            },
          ],
        },
        {
          path: "/cost-sheets",
          name: "cost-sheets",
          component: () => import("@/view/pages/cost-sheets/CostSheet.vue"),
          children: [
            {
              path: "list",
              name: "cost-sheets-list",
              component: () => import("@/view/pages/cost-sheets/List.vue"),
            },
            {
              path: "new",
              name: "cost-sheet-new",
              component: () => import("@/view/pages/cost-sheets/New.vue"),
            },
            {
              path: "edit/:id",
              name: "cost-sheet-edit",
              component: () => import("@/view/pages/cost-sheets/Edit.vue"),
            },
            {
              path: "total",
              name: "cost-sheets-total",
              component: () => import("@/view/pages/cost-sheets/Total.vue"),
            },
          ],
        },
        {
          path: "/notifications",
          name: "notifications",
          component: () => import("@/view/pages/notification/Notification.vue"),
          children: [
            {
              path: "list",
              name: "notifications-list",
              component: () => import("@/view/pages/notification/List.vue"),
            },
            {
              path: "new",
              name: "notification-new",
              component: () => import("@/view/pages/notification/New.vue"),
            },
            {
              path: "edit/:id",
              name: "notification-edit",
              component: () => import("@/view/pages/notification/Edit.vue"),
            },
          ],
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "personal-information",
              name: "profile-personal-information",
              component: () =>
                import("@/view/pages/profile/profile/PersonalInformation.vue"),
            },
            {
              path: "personal-information/edit",
              name: "profile-personal-information-edit",
              component: () =>
                import(
                  "@/view/pages/profile/profile/personal-information/Edit.vue"
                ),
            },
          ],
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/users/Users"),
          children: [
            {
              path: "list",
              name: "users-list",
              component: () => import("@/view/pages/users/List.vue"),
            },
            {
              path: "new",
              name: "users-new",
              component: () => import("@/view/pages/users/New.vue"),
            },
            {
              path: "edit/:id",
              name: "users-edit",
              component: () => import("@/view/pages/users/Edit.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
