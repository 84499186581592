export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    WORKERS: "Workers",
    ALL_WORKERS: "All Workers",
    CONSTRUCTIONS: "Constructions",
    WORKERS_AND_CONSTRUCTIONS: "Workers and Constructions",
    ALL_CONSTRUCTIONS: "All Constructions",
    HOURLY_RATE_ADDED: "Hourly rate successfully added to the database",
    FINANCE: "Finance",
    HOURLY_RATE: "Hourly Rate",
    ALL_HOURLY_RATES: "All Hourly Rates",
    NEW_HOURLY_RATE: "New Hourly Rate",
    COST_SHEET: "Cost Sheet",
    ALL_COST_SHEETS: "All Cost Sheets",
    TOTAL: "Total",
    USERS: "Users",
    ALL_USERS: "All Users",
  },
  USER: {
    DELETE_CONFIRMATION: "Are you sure you want to delete this user",
    DELETED_SUCCESS: "User successfully deleted from the database.",
    EXPORT_SUCCESS: "User export successful",
    EXPORT_ERROR: "User export unsuccessful",
    ALL: "All Users",
    EDIT_USER: "Edit User",
    NEW_USER: "Add New User",
    ADD_BUTTON: "Add User",
    NEW_PASSWORD: "New Password",
    REPEAT_NEW: "Repeat New Password",
    REPEAT_PASSWORD: "Repeat Password",
    NO_MATCH: "Repeated password does not match",
    ROLE: "Role",
    SELECT_ROLE: "Select Role",
    ADD_SUCCESS: "User successfully added to the database.",
    SUCCESS: "User successfully updated in the database.",
    MANDATORY: "Please enter all mandatory fields",
    THIS_MANDATORY: "This field is mandatory",
    LIST: "List of all users in the database.",
  },
  GENERAL: {
    FIELD_REQUIRED: "This field is required",
    ACTION: "Action",
    AMOUNT: "Amount",
    NEW: "New",
    ADD_HOURLYRATE: "Add Hourly Rate",
    SAVE_HOURLYRATE: "Save Hourly Rate",
    WEEKEND: "Weekend",
    TOTAL: "Total",
    TOTAL_WORKERS: "Total Workers",
    TOTAL_CONSTRUCTIONSITES: "Total Construction Sites",
    WORK_HOURS: "Work Hours",
    CONSTRUCTION_SITE: "Construction Site",
    DATE: "Date",
    YES: "Yes",
    NO: "No",
    EXPIRED: "Expired",
    HAS: "Has",
    DOESNT_HAVE: "Doesn't Have",
    ADD_NEW: "Add New",
    NAME: "Name",
    LAST_NAME: "Last Name",
    FIRST_LAST: "First and Last Name",
    ADDRESS: "Address",
    PASSPORT: "Passport",
    OIB: "OIB",
    MOBILE: "Mobile Number",
    EMAIL: "Email Address",
    FATHERS_NAME: "Father's Name",
    MOTHERS_NAME: "Mother's Name",
    SALARY_AND_HOURLYRATE: "Salary and Hourly Rate",
    SALARY: "Salary",
    OVERTIME: "Overtime",
    HOURLY_RATE: "Hourly Rate",
    OVERTIME_RATE: "Overtime Rate",
    WEEKEND_HOURLYRATE: "Weekend Hourly Rate",
    WEEKEND_HOURS: "Weekend Hours",
    DAILY_WAGE: "Daily Wage",
    NIGHT_HOURLYRATE: "Night Hourly Rate",
    NIGHT_HOURS: "Night Hours",
    WORK_PERMIT_EXPIRATION_DATE: "Work Permit Expiration Date",
    MEDICAL_EXPIRATION_DATE: "Medical Expiration Date",
    WORK_SAFETY: "Work Safety",
    FIRST_AID: "First Aid",
    GEDA: "GEDA",
    BORROWED_TOOLS: "Borrowed Tools",
    START: "Start",
    END: "End",
    PROOF: "Proof",
    BILL: "Bill",
    CHARGED: "Charged",
    TOOLS: "Tools",
    NOTE: "Note",
  },
  CONSTRUCTIONS: {
    EDIT_CONSTRUCTION: "Edit Construction",
    EDIT_CONSTRUCTION_NAME: "Edit Construction Name",
    EDIT_ADDRESS: "Edit Address",
    EDIT_PROOF: "Edit Proof",
    EDIT_BILL: "Edit Bill",
    EDIT_CHARGED: "Edit Charged",
    EDIT_START: "Change Start",
    EDIT_END: "Change End",
    EDIT_NOTE: "Edit Note",
    ADDED: "Construction successfully added to the database",
    DELETED: "Construction successfully deleted from the database",
    DEL_CONFIRM: "Do you want to delete the construction from the database?",
    EXPORT: "Construction successfully exported",
    EXPORT_ERR: "Failed to export construction",
  },
  ADD: {
    ADD_CONSTRUCTION: "Add New Construction",
    ADD_PROOF: "Add Proof",
    ADD_BILL: "Add Bill",
    ADD_WORKERS: "Add Workers",
    ADD_NEW_WORKER: "Add New Worker",
  },
  LIST: {
    ADDRESS: "Address",
    ALL_CONSTRUCTIONS: "All Constructions",
    ADD_CONSTRUCTION: "Add Construction",
    LIST_CONSTRUCTION: "List of Constructions in the Database",
    LIST_OF_ALL_WORKERS_IN_DATABASE: "List of all workers in the database.",
    LIST_OF_ALL_HOURLYRATES_IN_DATABASE:
      "List of all hourly rates in the database",
    NO_HOURLYRATES: "Currently no hourly rates added in the database",
    NO_CONSTRUCTIONS: "Currently no constructions added in the database.",
    NO_WORKERS_IN_DATABASE: "Currently no workers added in the database.",
    NEW_CONSTRUCTION: "New Construction",
    NEW_WORKER: "New Worker",
    NAME: "Name",
    ACTIONS: "Actions",
    FULL_NAME: "Full Name",
  },
  WORKERS: {
    EDIT_WORKER: "Edit Worker",
    PERSONAL_DETAILS: "Worker's Personal Details",
    WORKER_SUCCESS: "Worker successfully added to the database",
    EXPORT_SUCCESS: "Worker export successful",
    EXPORT_ERR: "Worker export unsuccessful",
    DELETE_CONFIRMATION: "Do you want to delete this worker?",
    DELETE_SUCCESS: "Worker successfully deleted from the database",
    UPDATE_SUCCESS: "Worker successfully updated in the database",
    UPDATE_ERR: "Failed to update worker",
  },
  COSTSHEET: {
    CONFIRMATION: "Are you sure you want to delete the cost sheet",
    ALL_TOTAL: "All tables with total costs",
    COST: "Cost",
    COSTSHEET: "Cost Sheet",
    IS_VARIABLE: "Is Variable",
    ADD_COSTSHEET: "Add Cost Sheet",
    ADDED: "Cost sheet successfully added to the database",
    NO_COSTSHEET_IN_DATABASE: "Currently no cost sheets added in the database",
    COSTSHEET_UPDATED: "Cost sheet successfully updated in the database",
    DELETED: "Cost sheet successfully deleted from the database",
    NEW_COSTSHEET: "New Cost Sheet",
    EDIT_COSTSHEET: "Edit Cost Sheet",
    COST_DESCRIPTION: "Cost Description",
    BILLING_DATE: "Billing Date",
    FROM_DATE: "From Date",
    TO_DATE: "To Date",
    LIST_OF_COSTSHEETS: "List of all cost sheets in the database",
    EXPORT: "Cost sheet successfully exported",
    EXPORT_ERR: "Failed to export cost sheet",
  },
  HOURLYRATE: {
    EDIT_HOURLYRATE: "Edit Hourly Rate",
    SUCCESS: "Hourly rate successfully updated in the database",
    EXPORT: "Hourly rate successfully exported",
    EXPORT_ERR: "Failed to export hourly rate",
  },
  ERROR: {
    SOMETHING_WENT_WRONG: "Something went wrong",
    OOPS: "Oops!",
    HOW_DID_YOU_GET_HERE: "How did you get here?",
    SORRY_CANT_FIND_PAGE: "Sorry, we can't find the page you're looking for.",
    URL_MISSPELLING: "The entered URL may be misspelled",
    OR: "or the page you are looking for no longer exists",
    ERR: "Error",
    NOTHING_HERE: "There's nothing more to do here",
    WORKING_ON_IT: "We're working on it",
  },
  AUTH: {
    GENERAL: {
      WELCOME: "Welcome to Construction Tycoon",
      OR: "Or",
      DETAILS: "Enter details to sign in",
      SUBMIT_BUTTON: "Submit",
      CANCEL: "Cancel",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Sign in to your account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Register",
      DESC: "Enter your details to create account",
      SUCCESS: "Your account has been successfully registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Full Name",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is invalid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Agreement is required",
      TERMS_AND_CONDITIONS: "Terms & Conditions",
      AGREE: "Agree",
      NOT_FOUND: "{{name}} not found",
      INVALID_LOGIN: "Invalid login credentials",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum length:",
      MAX_LENGTH_FIELD: "Maximum length:",
      INVALID_FIELD: "Field is invalid",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "By Status",
      BY_TYPE: "By Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "E-commerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers List",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Delete Customer",
        DESCRIPTION:
          "Are you sure you want to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is being deleted...",
        MESSAGE: "Customer has been successfully deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Delete Customers",
        DESCRIPTION:
          "Are you sure you want to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are being deleted...",
        MESSAGE: "Selected customers have been successfully deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Status of selected customers has been successfully updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been successfully created",
      },
    },
  },
};
