const USER = "user";

export const getUser = () => {
  return window.localStorage.getItem(USER);
};

export const saveUser = (user) => {
  window.localStorage.setItem(USER, user);
};

export const destroyUser = () => {
  window.localStorage.removeItem(USER);
};

export default { getUser, saveUser, destroyUser };
