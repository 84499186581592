export const locale = {
  TRANSLATOR: {
    SELECT: "Wählen Sie Ihre Sprache",
  },
  MENU: {
    NEW: "neu",
    ACTIONS: "Aktionen",
    CREATE_POST: "Beitrag erstellen",
    PAGES: "Seiten",
    FEATURES: "Funktionen",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    WORKERS: "Arbeiter",
    ALL_WORKERS: "Alle Arbeiter",
    CONSTRUCTIONS: "Baustellen",
    WORKERS_AND_CONSTRUCTIONS: "Arbeiter und Baustellen",
    ALL_CONSTRUCTIONS: "Alle Baustellen",
    HOURLY_RATE_ADDED: "Stundensatz erfolgreich zur Datenbank hinzugefügt",
    FINANCE: "Finanzen",
    HOURLY_RATE: "Stundensatz",
    ALL_HOURLY_RATES: "Alle Stundensätze",
    NEW_HOURLY_RATE: "Neuer Stundensatz",
    COST_SHEET: "Kostenblatt",
    ALL_COST_SHEETS: "Alle Kostenblätter",
    TOTAL: "Gesamt",
    USERS: "Benutzer",
    ALL_USERS: "Alle Benutzer",
  },
  USER: {
    DELETE_CONFIRMATION: "Möchten Sie diesen Benutzer wirklich löschen",
    DELETED_SUCCESS: "Benutzer erfolgreich aus der Datenbank gelöscht.",
    EXPORT_SUCCESS: "Benutzerexport erfolgreich",
    EXPORT_ERROR: "Benutzerexport fehlgeschlagen",
    ALL: "Alle Benutzer",
    EDIT_USER: "Benutzer bearbeiten",
    NEW_USER: "Neuen Benutzer hinzufügen",
    ADD_BUTTON: "Benutzer hinzufügen",
    NEW_PASSWORD: "Neues Passwort",
    REPEAT_NEW: "Neues Passwort wiederholen",
    REPEAT_PASSWORD: "Passwort wiederholen",
    NO_MATCH: "Wiederholtes Passwort stimmt nicht überein",
    ROLE: "Rolle",
    SELECT_ROLE: "Rolle auswählen",
    ADD_SUCCESS: "Benutzer erfolgreich zur Datenbank hinzugefügt.",
    SUCCESS: "Benutzer erfolgreich in der Datenbank aktualisiert.",
    MANDATORY: "Bitte geben Sie alle obligatorischen Felder ein",
    THIS_MANDATORY: "Dieses Feld ist obligatorisch",
    LIST: "Liste aller Benutzer in der Datenbank.",
  },
  GENERAL: {
    FIELD_REQUIRED: "Dieses Feld ist erforderlich",
    ACTION: "Aktion",
    AMOUNT: "Menge",
    NEW: "Neu",
    ADD_HOURLYRATE: "Stundensatz hinzufügen",
    SAVE_HOURLYRATE: "Stundensatz speichern",
    WEEKEND: "Wochenende",
    TOTAL: "Gesamt",
    TOTAL_WORKERS: "Gesamtzahl der Arbeiter",
    TOTAL_CONSTRUCTIONSITES: "Gesamtzahl der Baustellen",
    WORK_HOURS: "Arbeitsstunden",
    CONSTRUCTION_SITE: "Baustelle",
    DATE: "Datum",
    YES: "Ja",
    NO: "Nein",
    EXPIRED: "Abgelaufen",
    HAS: "Hat",
    DOESNT_HAVE: "Hat nicht",
    ADD_NEW: "Neu hinzufügen",
    NAME: "Name",
    LAST_NAME: "Nachname",
    FIRST_LAST: "Vor- und Nachname",
    ADDRESS: "Adresse",
    PASSPORT: "Pass",
    OIB: "OIB",
    MOBILE: "Handynummer",
    EMAIL: "E-Mail-Adresse",
    FATHERS_NAME: "Vatersname",
    MOTHERS_NAME: "Müttersname",
    SALARY_AND_HOURLYRATE: "Gehalt und Stundensatz",
    SALARY: "Gehalt",
    OVERTIME: "Überstunden",
    HOURLY_RATE: "Stundensatz",
    OVERTIME_RATE: "Überstundensatz",
    WEEKEND_HOURLYRATE: "Wochenend-Stundensatz",
    WEEKEND_HOURS: "Arbeitsstunden am Wochenende",
    DAILY_WAGE: "Tageslohn",
    NIGHT_HOURLYRATE: "Nacht-Stundensatz",
    NIGHT_HOURS: "Nachtstunden",
    WORK_PERMIT_EXPIRATION_DATE: "Arbeitserlaubnis läuft ab am",
    MEDICAL_EXPIRATION_DATE: "Medizinische Untersuchung läuft ab am",
    WORK_SAFETY: "Arbeitssicherheit",
    FIRST_AID: "Erste Hilfe",
    GEDA: "GEDA",
    BORROWED_TOOLS: "Geliehene Werkzeuge",
    START: "Start",
    END: "Ende",
    PROOF: "Nachweis",
    BILL: "Rechnung",
    CHARGED: "Berechnet",
    TOOLS: "Werkzeuge",
    NOTE: "Hinweis",
  },
  CONSTRUCTIONS: {
    EDIT_CONSTRUCTION: "Baustelle bearbeiten",
    EDIT_CONSTRUCTION_NAME: "Baustellenname bearbeiten",
    EDIT_ADDRESS: "Adresse bearbeiten",
    EDIT_PROOF: "Nachweis bearbeiten",
    EDIT_BILL: "Rechnung bearbeiten",
    EDIT_CHARGED: "Berechnetes bearbeiten",
    EDIT_START: "Start ändern",
    EDIT_END: "Ende ändern",
    EDIT_NOTE: "Hinweis bearbeiten",
    ADDED: "Baustelle erfolgreich zur Datenbank hinzugefügt",
    DELETED: "Baustelle erfolgreich aus der Datenbank gelöscht",
    DEL_CONFIRM: "Möchten Sie die Baustelle aus der Datenbank löschen?",
    EXPORT: "Baustelle erfolgreich exportiert",
    EXPORT_ERR: "Export der Baustelle fehlgeschlagen",
  },
  ADD: {
    ADD_CONSTRUCTION: "Neue Baustelle hinzufügen",
    ADD_PROOF: "Nachweis hinzufügen",
    ADD_BILL: "Rechnung hinzufügen",
    ADD_WORKERS: "Arbeiter hinzufügen",
    ADD_NEW_WORKER: "Neuen Arbeiter hinzufügen",
  },
  LIST: {
    ADDRESS: "Adresse",
    ALL_CONSTRUCTIONS: "Alle Baustellen",
    ADD_CONSTRUCTION: "Baustelle hinzufügen",
    LIST_CONSTRUCTION: "Liste der Baustellen in der Datenbank",
    LIST_OF_ALL_WORKERS_IN_DATABASE: "Liste aller Arbeiter in der Datenbank.",
    LIST_OF_ALL_HOURLYRATES_IN_DATABASE:
      "Liste aller Stundensätze in der Datenbank",
    NO_HOURLYRATES: "Aktuell keine Stundensätze in der Datenbank hinzugefügt",
    NO_CONSTRUCTIONS: "Aktuell keine Baustellen in der Datenbank hinzugefügt.",
    NO_WORKERS_IN_DATABASE:
      "Aktuell keine Arbeiter in der Datenbank hinzugefügt.",
    NEW_CONSTRUCTION: "Neue Baustelle",
    NEW_WORKER: "Neuer Arbeiter",
    NAME: "Name",
    ACTIONS: "Aktionen",
    FULL_NAME: "Vor- und Nachname",
  },
  WORKERS: {
    EDIT_WORKER: "Arbeiter bearbeiten",
    PERSONAL_DETAILS: "Persönliche Angaben des Arbeiters",
    WORKER_SUCCESS: "Arbeiter erfolgreich zur Datenbank hinzugefügt",
    EXPORT_SUCCESS: "Arbeiterexport erfolgreich",
    EXPORT_ERR: "Arbeiterexport fehlgeschlagen",
    DELETE_CONFIRMATION: "Möchten Sie diesen Arbeiter wirklich löschen?",
    DELETE_SUCCESS: "Arbeiter erfolgreich aus der Datenbank gelöscht",
    UPDATE_SUCCESS: "Arbeiter erfolgreich in der Datenbank aktualisiert",
    UPDATE_ERR: "Arbeiter konnte nicht aktualisiert werden",
  },
  COSTSHEET: {
    CONFIRMATION: "Möchten Sie das Kostenblatt wirklich löschen",
    ALL_TOTAL: "Alle Tabellen mit Gesamtkosten",
    COST: "Kosten",
    COSTSHEET: "Kostenblatt",
    IS_VARIABLE: "Ist variabel",
    ADD_COSTSHEET: "Kostenblatt hinzufügen",
    ADDED: "Kostenblatt erfolgreich zur Datenbank hinzugefügt",
    NO_COSTSHEET_IN_DATABASE:
      "Aktuell keine Kostenblätter in der Datenbank hinzugefügt",
    COSTSHEET_UPDATED: "Kostenblatt erfolgreich in der Datenbank aktualisiert",
    DELETED: "Kostenblatt erfolgreich aus der Datenbank gelöscht",
    NEW_COSTSHEET: "Neues Kostenblatt",
    EDIT_COSTSHEET: "Kostenblatt bearbeiten",
    COST_DESCRIPTION: "Kostenbeschreibung",
    BILLING_DATE: "Rechnungsdatum",
    FROM_DATE: "Von Datum",
    TO_DATE: "Bis Datum",
    LIST_OF_COSTSHEETS: "Liste aller Kostenblätter in der Datenbank",
    EXPORT: "Kostenblatt erfolgreich exportiert",
    EXPORT_ERR: "Export des Kostenblatts fehlgeschlagen",
  },
  HOURLYRATE: {
    EDIT_HOURLYRATE: "Stundensatz bearbeiten",
    SUCCESS: "Stundensatz erfolgreich in der Datenbank aktualisiert",
    EXPORT: "Stundensatz erfolgreich exportiert",
    EXPORT_ERR: "Export des Stundensatzes fehlgeschlagen",
  },
  ERROR: {
    SOMETHING_WENT_WRONG: "Etwas ist schiefgelaufen",
    OOPS: "Hoppla!",
    HOW_DID_YOU_GET_HERE: "Wie sind Sie hierher gekommen?",
    SORRY_CANT_FIND_PAGE:
      "Entschuldigung, wir können die von Ihnen gesuchte Seite nicht finden.",
    URL_MISSPELLING:
      "Die eingegebene URL ist möglicherweise falsch geschrieben",
    OR: "oder die Seite, die Sie suchen, existiert nicht mehr",
    ERR: "Fehler",
    NOTHING_HERE: "Hier gibt es nichts mehr zu tun",
    WORKING_ON_IT: "Wir arbeiten daran",
  },
  AUTH: {
    GENERAL: {
      WELCOME: "Willkommen bei Construction Tycoon",
      OR: "Oder",
      DETAILS: "Geben Sie Details ein, um sich anzumelden",
      SUBMIT_BUTTON: "Senden",
      CANCEL: "Abbrechen",
      NO_ACCOUNT: "Sie haben kein Konto?",
      SIGNUP_BUTTON: "Registrieren",
      FORGOT_BUTTON: "Passwort vergessen",
      BACK_BUTTON: "Zurück",
      PRIVACY: "Datenschutz",
      LEGAL: "Rechtlich",
      CONTACT: "Kontakt",
    },
    LOGIN: {
      TITLE: "Anmelden bei Ihrem Konto",
      BUTTON: "Anmelden",
    },
    FORGOT: {
      TITLE: "Passwort vergessen?",
      DESC: "Geben Sie Ihre E-Mail-Adresse ein, um das Passwort zurückzusetzen",
      SUCCESS: "Ihr Konto wurde erfolgreich zurückgesetzt.",
    },
    REGISTER: {
      TITLE: "Registrieren",
      DESC: "Geben Sie Ihre Daten ein, um ein Konto zu erstellen",
      SUCCESS: "Ihr Konto wurde erfolgreich registriert.",
    },
    INPUT: {
      EMAIL: "E-Mail",
      FULLNAME: "Vor- und Nachname",
      PASSWORD: "Passwort",
      CONFIRM_PASSWORD: "Passwort bestätigen",
      USERNAME: "Benutzername",
    },
    VALIDATION: {
      INVALID: "{{name}} ist ungültig",
      REQUIRED: "{{name}} ist erforderlich",
      MIN_LENGTH: "Mindestlänge von {{name}} beträgt {{min}}",
      AGREEMENT_REQUIRED: "Die Zustimmung ist erforderlich",
      TERMS_AND_CONDITIONS: "Allgemeine Geschäftsbedingungen",
      AGREE: "Zustimmen",
      NOT_FOUND: "{{name}} nicht gefunden",
      INVALID_LOGIN: "Ungültige Anmeldeinformationen",
      REQUIRED_FIELD: "Erforderliches Feld",
      MIN_LENGTH_FIELD: "Mindestlänge:",
      MAX_LENGTH_FIELD: "Maximale Länge:",
      INVALID_FIELD: "Feld ist ungültig",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Anzahl ausgewählter Datensätze: ",
      ALL: "Alle",
      SUSPENDED: "Suspendiert",
      ACTIVE: "Aktiv",
      FILTER: "Filter",
      BY_STATUS: "Nach Status",
      BY_TYPE: "Nach Typ",
      BUSINESS: "Unternehmen",
      INDIVIDUAL: "Einzel",
      SEARCH: "Suche",
      IN_ALL_FIELDS: "in allen Feldern",
    },
    ECOMMERCE: "E-Commerce",
    CUSTOMERS: {
      CUSTOMERS: "Kunden",
      CUSTOMERS_LIST: "Kundenliste",
      NEW_CUSTOMER: "Neuer Kunde",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Kunden löschen",
        DESCRIPTION: "Möchten Sie diesen Kunden wirklich dauerhaft löschen?",
        WAIT_DESCRIPTION: "Kunde wird gelöscht...",
        MESSAGE: "Kunde wurde erfolgreich gelöscht",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Kunden löschen",
        DESCRIPTION:
          "Möchten Sie die ausgewählten Kunden wirklich dauerhaft löschen?",
        WAIT_DESCRIPTION: "Kunden werden gelöscht...",
        MESSAGE: "Ausgewählte Kunden wurden erfolgreich gelöscht",
      },
      UPDATE_STATUS: {
        TITLE: "Status wurde für ausgewählte Kunden aktualisiert",
        MESSAGE:
          "Status der ausgewählten Kunden wurde erfolgreich aktualisiert",
      },
      EDIT: {
        UPDATE_MESSAGE: "Kunde wurde aktualisiert",
        ADD_MESSAGE: "Kunde wurde erfolgreich erstellt",
      },
    },
  },
};
