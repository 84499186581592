// action types
export const UPDATE_USER_INFO = "updateUserInfo";

// mutation types
export const SET_USER_INFO = "setUserInfo";

const state = {
  user_info: {
    photo: "media/users/300_21.jpg",
    name: "Ime",
    surname: "Prezime",
    company_name: "",
    job: "Administrator",
    email: "info@constructiontycoons.com",
    phone: "44(76)34254578",
    company_site: "",
  },
};

const getters = {
  currentUserInfo(state) {
    return state.user_info;
  },

  currentUserPhoto(state) {
    return state.user_info.photo;
  },
};

const actions = {
  [UPDATE_USER_INFO](context, payload) {
    context.commit(SET_USER_INFO, payload);
  },
};

const mutations = {
  [SET_USER_INFO](state, user_info) {
    state.user_info = user_info;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
